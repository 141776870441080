<!-- eslint-disable -->
<template>
  <div class="message-count abroad accurate" v-infinite-scroll="load">
    <p class="index-title">预警舆情</p>
    <!-- 头部  -->
    <div class="message-top">
      <!-- 舆情 -->
      <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="全部数据" name="liebiaoList"></el-tab-pane>
        <el-tab-pane label="视频" name="videoList"></el-tab-pane>
      </el-tabs> -->
      <div class="message-yuqing" style="margin-top: 15px">
        <div>
          <span class="message-top-title">舆情调性</span>
          <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
          <el-checkbox-group v-model="checkedTonality" @change="handleCheckedCitiesChange">
            <el-checkbox v-for="city in cities" :label="city.value" :key="city.value">{{ city.label }}</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="zhankai-change" @click="zhankaiChange">
          <span class="xia-span">{{ zhankai ? "收起" : "展开" }}</span>
          <span>
            <img class="xia-span-img" src="../../assets/img/views/more.png" alt v-show="zhankai" />
            <img src="../../assets/img/views/xia.png" alt v-show="!zhankai" class="xia-img" />
          </span>
        </div>
      </div>
      <!-- 区域 -->
      <div :class="['animation', zhankai ? 'shouHeight' : 'defultHeight']">
        <!-- <div v-if="regional.length > 0">
          <div class="line"></div>
          <div class="message-region">
            <span class="message-top-title">监测区域</span>
            <div class="el-checkbox-group"></div>
            <el-radio v-for="itme in regional" v-model="checkedAreas" :key="itme.areaId" :label="itme.areaId">{{
        itme.placeName }}</el-radio>
          </div>
        </div> -->
        <!-- 监测区域 -->
        <!-- <div class="line"></div> -->
        <!-- 涉及区域 -->
        <!-- <div class="message-link">
          <span class="message-top-title">涉及区域</span>
          <div class="el-checkbox-group">
            <el-checkbox
              v-for="city in regional"
              v-model="city.checked"
              @change="handleCheckAllChanges(city)"
              :key="city.areaId"
            >{{city.placeName}}</el-checkbox>
          </div>
        </div>
                          <div class="line"></div>-->
        <!-- 来源媒体 -->
        <div class="message-link" v-show="activeName == 'liebiaoList'">
          <span class="message-top-title huanjie">媒体环节</span>
          <el-checkbox v-for="city in media" :key="city.id" v-model="city.checked"
            @change="handleCheckAllChangess(city)">{{ city.mediaClassifyName }}</el-checkbox>
        </div>
        <div class="line" v-show="activeName == 'liebiaoList'"></div>
        <div class="message-link">
          <span class="message-top-title huanjie">媒体属性</span>
          <el-checkbox v-for="city in Attribute" :key="city.id" v-model="city.checked"
            @change="handleCheckAllChangesss(city)">{{ city.mediaClassifyName }}</el-checkbox>
        </div>
        <div class="line"></div>
        <div class="message-link">
          <span class="message-top-title huanjie">媒体级别</span>
          <el-checkbox v-for="city in mediaLevel" :key="city.id" v-model="city.checked"
            @change="handleCheckAllChangessss(city)">{{ city.mediaClassifyName }}</el-checkbox>
        </div>
      </div>
      <div class="line"></div>
      <div class="message-link">
        <InputGroupVue ref="InputGroupVue" listType='sentiment' />
      </div>
      <div class="line"></div>
      <div class="message-time clear">
        <!-- 表单 -->
        <el-form :inline="true" :model="formInline" class="demo-form-inline clear">
          <div style="float: left">
            <span class="el_font">事件</span>
            <el-cascader class="eventing" placeholder="请选择事件" :props="props" clearable
              v-model="formInline.value"></el-cascader>
            <span class="el_font" style="margin-left:32px">时间</span>
            <el-date-picker v-model="formInline.timer[0]" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="开始日期">
            </el-date-picker>
            -
            <el-date-picker v-model="formInline.timer[1]" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="结束日期">
            </el-date-picker>
            <!-- <el-checkbox class="yujing" v-model="warning">已预警</el-checkbox> -->
            <el-checkbox class="yujing" v-model="repeatData">隐藏重复数据</el-checkbox>
          </div>
        </el-form>
      </div>
      <div class="line"></div>
      <div class="message-link" style="align-items: center;justify-content: space-between;">
        <div>
        </div>
        <div>
          <el-button type="primary" plain style="width: 60px" size="small" @click="Traceability"
            v-if="$store.state.user.organizationId == 3034">溯源</el-button>
          <el-button type="primary" style="width: 60px" size="small" @click="search">查询</el-button>
          <el-button size="small" class="chongzhi" @click="chongzhi">重置</el-button>
          <el-button style="width: 60px" size="small" class="refresh" @click="search">刷新</el-button>
        </div>
      </div>
    </div>
    <!-- 头部  -->
    <!-- 底部 -->
    <div style="padding-bottom: 24px">
      <!-- 列表 -->
      <!-- 精简按钮 || 列表按钮  -->
      <div style="margin-top: 16px;width: 100%;display: flex;justify-content: end;">
        <el-radio-group v-if="activeName == 'liebiaoList'" v-model="radioValue" size="medium" @change="changeradio">
          <el-radio-button label="1">
            <img v-if="radioValue == '2'" height="13px" src="../../assets/img/views/jinjianBlack.png" alt="" />
            <img v-else height="13px" src="../../assets/img/views/jinjian.png" alt="" />
            <span v-if="radioValue == '2'" style="color: #666; font-size: 15px; margin-left: 4px">精简</span>
            <span v-else style="color: #fff; font-size: 15px; margin-left: 4px">精简</span>
          </el-radio-button>
          <el-radio-button label="2">
            <img v-if="radioValue == 2" height="13px" src="../../assets/img/views/liebiao.png" alt="" />
            <img v-else height="13px" src="../../assets/img/views/liebiaoBlack.png" alt="" />
            <span v-if="radioValue == '1'" style="color: #666; font-size: 15px; margin-left: 4px">列表</span>
            <span v-else style="color: #fff; font-size: 15px; margin-left: 4px">列表</span>
          </el-radio-button>
        </el-radio-group>
      </div>
      <div class="message-bottom">
        <!-- 列表 -->
        <div v-loading="loading">
          <div v-if="textData.length != 0">
            <div :class="['bottom-count', item.newData ? 'new-data' : '']" newData v-for="(item, i) in textData"
              :key="item.accurateId" @mouseenter="oldData(item)">
              <!-- 列表 -->
              <template v-if="radioValue == 2">
                <div class="bottom-count-box">
                  <div :class="['type', item.tonalState == 2 ? 'red' : item.tonalState == 1 ? 'neutral' : 'obverse',]">
                    {{ item.tonalState == 2 ? "负面" : item.tonalState == 0 ? "正面" : "中性" }}
                  </div>
                  <div v-if="item.mediaLink != null" class="type website">
                    {{ item.mediaLink }}
                  </div>
                </div>
                <div class="bottom-count-text">
                  <div class="bottom-comment-box">
                    <div style="display: flex">
                      <span v-show="item.isEarlyWarn == 1" class="comment-box-yujing" style="margin-left: 0">已预警</span>
                      <p class="bottom-title" v-html="item.title ? item.title : ''" @click="hangel(item)"></p>
                      <img class="zb_sentiment_copy" style="width: 24px; height: 24px"
                        src="../../assets/img/views/copy_b.png" @click="myCopy(item, 2)" alt />
                    </div>
                    <div class="bottom-comment">
                      <span class="original" @click="lookOriginal(item)">
                        <span>原文链接</span>
                      </span>
                      <img class="zb_sentiment_copy" src="../../assets/img/views/copy_b.png" @click="myCopy(item, 1)"
                        alt />
                      <!-- <span :class="['comment-pinglun', !item.likeAmount ? 'grey' : 'blue']">
                        相似({{ item.likeAmount ? item.likeAmount : "0" }})
                      </span> -->
                      <span class="comment-pinglun">
                        <i>
                          <img v-if="item.isRead == '1'" src="../../assets/img/views/greenmessage.png" />
                          <img v-else src="../../assets/img/views/redmessage.png" />
                        </i>
                        <span :class="[item.isRead == '1' ? 'yidu' : ' ']">
                          {{ item.isRead == "1" ? "已读" : "未读" }}
                        </span>
                      </span>
                    </div>
                  </div>
                  <!-- 评论数量等 -->
                  <p class="bottom-text" v-html="item.abstracts ? item.abstracts : ''" @click="hangel(item)"></p>
                  <div class="bottom-text-box">
                    <div class="bottom-text-caozuo">
                      <span class="bottom-meiti">
                        作者ID：{{ item.interiorsMediaLevel ? item.interiorsMediaLevel : "无" }}
                      </span>
                      <span class="bottom-meiti">
                        媒体性质：
                        {{ (item.mediaLink ? item.mediaLink : "其它") + "/"
    + (item.mediaAttribute ? item.mediaAttribute : "其它") + "/"
    + (item.mediaLevel ? item.mediaLevel : "其它") }}
                      </span>
                      <el-tooltip class="item"
                        :content="(item.source ? item.source : '无') + '/' + (item.author ? item.author : '无')"
                        effect="dark" placement="top">
                        <span class="publics source" style="display: flex">
                          来源/作者：
                          <span v-html="item.source ? item.source : '无'"></span>
                          <span>/</span>
                          <span v-html="item.author ? item.author : '无'"></span>
                        </span>
                      </el-tooltip>
                      <span class="publics bottom-gengduo">
                        事件分类：
                        <span>
                          {{ item.eventList[0] ? item.eventList[0] : "无" }}
                        </span>
                        <el-tooltip v-if="item.eventClass && item.eventList.length >= 2" class="item" effect="dark"
                          placement="top">
                          <div slot="content" v-html="item.eventClass"></div>
                          <span class="gengduo-link">(更多)</span>
                        </el-tooltip>
                      </span>
                      <span class="publics bottom-key">
                        关键字：
                        <span v-if="item.keyword">
                          <span v-for="(ite, indx) in item.keywordList" :key="indx"
                            :class="[ite != item.keywordList[item.keywordList.length - 1] ? 'styleMargin' : '',]">
                            {{ ite }}
                          </span>
                        </span>
                        <span v-else>无</span>
                        <el-tooltip v-if="item.keyword && item.keywordArry.length > 3" class="item" effect="dark"
                          placement="top">
                          <div slot="content" v-html="item.keyword"></div>
                          <span class="gengduo-link">(更多)</span>
                        </el-tooltip>
                      </span>
                    </div>
                    <span class="publics bottom-time">
                      {{ item.releaseTime ? item.releaseTime : "无" }}
                    </span>
                  </div>
                  <div class="bottom-operation">
                    <!-- 设置标签 -->
                    <el-select size="small" ref="selectLael" class="select-biaoqian" v-model="item.setLabel"
                      placeholder="设置标签" @change="changeLabel(item)">
                      <el-option v-for="(ite, index) in biaoqianList" :disabled="ite.disabled" :key="index"
                        :label="ite.labelName" :value="ite.labelId"></el-option>
                    </el-select>
                    <div class="operation-box">
                      <div v-if="$store.state.user.isreport" class="operation-shoucang my-caozuo"
                        @click="dataReport(item)">
                        <span>
                          <span>
                            <img style="width: 16px; height: 16px"
                              src="../../assets/img/views/monitoringScheme/sahngchuan.png" alt />
                          </span>
                        </span>
                        <span>
                          {{ item.isReport == 1 ? "已上报" : "数据上报" }}
                        </span>
                      </div>
                      <div class="operation-copy my-caozuo" @click="copy(item)">
                        <span>
                          <span>
                            <img src="../../assets/img/views/fuzhi.png" alt />
                          </span>
                        </span>
                        <span>复制全部</span>
                      </div>
                      <div @click="quickCopy2(item)" class="my-caozuo">
                        <span style="margin: 0; color: black; font-size: 16px" class="el-icon-link"></span>
                        复制作者和链接
                      </div>
                      <div @click="quickCopy(item)" class="my-caozuo">
                        <span style="margin: 0; color: black; font-size: 16px" class="el-icon-link"></span>
                        复制链接和标题
                      </div>
                      <div class="operation-jietu my-caozuo" @click="screenShot(item)"
                        :class="item.disabled || item.fastPic ? 'disabled' : ''">
                        <span>
                          <span>
                            <img src="../../assets/img/views/tupian.png" alt />
                          </span>
                        </span>
                        <span>页面截图</span>
                      </div>
                      <div class="operation-shoucang my-caozuo" @click="editShouc(item)">
                        <span>
                          <span>
                            <img v-if="item.isCollect == '1'" src="~@/assets/img/views/yellowsc.png" alt />
                            <img v-else src="~@/assets/img/views/shoucang.png" alt />
                          </span>
                        </span>
                        <span :class="[item.isCollect == '1' ? 'yishoucang' : ' ']">
                          {{ item.isCollect == "1" ? "已收藏" : "收藏" }}
                        </span>
                      </div>
                      <div class="operation-delete my-caozuo" @click="deletes(item)">
                        <span>
                          <span>
                            <img src="../../assets/img/views/delete.png" alt />
                          </span>
                        </span>
                        <span>删除</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <!-- 精简 -->
              <template v-else>
                <div class="bottom-jingjian-card">
                  <div class="bottom-jingjian-card-L">
                    <div :class="['type', item.tonalState == 2 ? 'red' : item.tonalState == 1 ? 'neutral' : 'obverse']">
                      {{ item.tonalState == 2 ? "负面" : item.tonalState == 0 ? "正面" : "中性" }}
                    </div>
                    <p class="bottom-title M-L" v-html="item.title ? item.title : ''" @click="hangel(item)"></p>
                    <span class="original" @click="lookOriginal(item)">
                      <img style="position: relative; top: 0px" src="../../assets/img/views/wenzhang.png" alt />
                      <span>查看原文</span>
                    </span>
                  </div>
                  <div class="bottom-jingjian-card-R">
                    <span class="publics M-L">
                      {{ item.releaseTime ? item.releaseTime : "无" }}
                    </span>
                    <!-- <span :class="['comment-pinglun', !item.likeAmount ? 'grey' : 'blue',]">
                      相似({{ item.likeAmount ? item.likeAmount : "0" }})
                    </span> -->
                    <span class="comment-pinglun">
                      <i>
                        <img v-if="item.isRead == '1'" src="../../assets/img/views/greenmessage.png" alt />
                        <img v-else src="../../assets/img/views/redmessage.png" alt />
                      </i>
                      <span :class="[item.isRead == '1' ? 'yidu' : ' ']">
                        {{ item.isRead == "1" ? "已读" : "未读" }}
                      </span>
                    </span>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="analyse-container" v-else>
            <div class="analyse-container-img">
              <img src="../../assets/img/views/analyse-img.png" />
              <p>暂无数据</p>
            </div>
          </div>
          <p v-if="loading2" class="loading">加载中...</p>
        </div>
      </div>
    </div>
    <deleteDialog ref="openDialog" @deleteFn="deleteFn" />
  </div>
</template>

<script>
import {
  accurateList,
  conditionList,
  setLabel,
  modifyLabel,
  screenShot,
  sentimentEdit,
  deleteAccurate,
  deleteLabel,
} from "../../api/accurate/list";
import {
  selectDataSchemeList,
  remove,
  serchEdit,
} from "../../api/index/index.js";
import deleteDialog from "../../components/deleteDialog";
import { getIncident, dataReport } from "../../api/message/list";
import InputGroupVue from '@/components/InputGroup.vue';
export default {
  data() {
    return {
      //新数据产生 true 否则 false 
      isNewData: false,
      isDefault: -1,
      activeSerchSave: -1,
      serchSaveData: [],
      serchSaveTitle: "",
      serchSaveDialog: false,
      newDataSearch: null,
      activeName: "liebiaoList",
      messageListPageNum: 0,
      warningList: [],
      loadingUl: false,
      table: false,
      radioValue: 2, // 精简1 || 列表2
      // 截图是否置灰
      disables: false,
      loading: false,
      loading2: false,
      // 开始时间
      startTime: "",
      // 结束时间
      endTime: "",
      // 是否展开收起
      zhankai: false,
      // 是否预警
      warning: true,
      checkAll: false,
      checkAlls: false,
      checkAllss: false,
      cities: [
        {
          label: "负面",
          value: 2,
        },
        {
          label: "中性",
          value: 1,
        },
        {
          label: "正面",
          value: 0,
        },
      ],
      // 涉及区域
      regional: [],
      checkedAreas: "",
      // 媒体环节
      media: [],
      checkedMedia: [],
      // 舆情调性
      checkedTonality: [],
      // 媒体属性
      Attribute: [],
      mediaAttribute: [],
      // 媒体级别
      mediaLevel: [],
      Level: [],
      formInline: {
        region: "title",
        input1: "",
        value: "",
        timer: [],
      },
      props: {
        lazy: true,
        checkStrictly: true,
        async lazyLoad(node, resolve) {
          const { level } = node;
          let data = {};
          if (node.level == 0) {
            data.parentId = 1;
          }
          if (node.level != 0) {
            data = {
              parentId: node.data.eventId,
            };
          }
          if (node.level < 3) {
            const res = await getIncident(data);
            if (res != undefined) {
              if (res.data.data.length == 0) {
                node.data.leaf = true;
                return resolve([]);
              } else {
                const nodes = res.data.data.map((item) => ({
                  value: item,
                  label: item.eventName,
                  eventId: item.eventId,
                  leaf: level >= 10,
                }));
                resolve(nodes);
              }
            }
          } else {
            node.data.leaf = true;
            return resolve([]);
          }
        },
      },
      // 设置标签
      biaoqian: "",
      // 用来存储旧的标签
      oldLabel: 0,
      biaoqianList: [],
      // 事件下拉
      options: [
        {
          label: "1",
          value: "2",
        },
        {
          label: "2",
          value: "3",
        },
      ],
      // 内容
      textData: [],
      //页数
      tablePage: {
        page: 1,
        pageSize: 20,
        total: 0,
      },
      dataList: {},
      //   是否隐藏重复数据
      repeatData: false,
    };
  },
  methods: {
    async isDefaultChangeAll() {
      let data = [];
      this.isDefault = -1;
      this.serchSaveData.forEach((x) => {
        if (x.isDefault === 1) {
          data.push({
            id: x.id,
            isDefault: 0,
            schemeContent: x.schemeContent,
          });
        }
      });
      await serchEdit(data);
      this.$message({
        type: "success",
        message: "取消成功！",
      });
    },
    async isDefaultChange(value) {
      let data = [];
      this.serchSaveData.forEach((x) => {
        if (x.id !== value.id && x.isDefault === 1) {
          data.push({
            id: x.id,
            isDefault: 0,
            schemeContent: x.schemeContent,
          });
        }
      });
      data.push({
        id: value.id,
        isDefault: 1,
        schemeContent: value.schemeContent,
      });
      await serchEdit(data);
      this.$message({
        type: "success",
        message: "启用成功！",
      });
    },
    planYes(e, val, i) {
      // "DIV" == 选择   "IMG" == 删除
      if (e.target.nodeName == "DIV") {
        this.activeSerchSave = i;
        let data = JSON.parse(val.schemeContent);
        this.checkAll = data.checkAll;
        this.warning = data.isEarlyWarn;
        this.repeatData = data.dataExist;
        this.checkedTonality = data.tonalState;
        this.checkedAreas = data.checkedAreas;
        this.media = data.media;
        this.Attribute = data.Attribute;
        this.mediaLevel = data.mediaLevel;
        this.formInline.input1 = data.input1;
        this.formInline.region = data.region;
        this.formInline.timer = data.timer;
        this.Level = data.mediaLevel.filter(v => v.checked)
        this.mediaAttribute = data.Attribute.filter(v => v.checked)
        this.checkedMedia = data.media.filter(v => v.checked)
        if (data.value) {
          document.querySelector(".eventing input").value =
            data.value[0].eventName;
        } else {
          this.formInline.value = '';
          document.querySelector(".eventing input").value = "";
        }
        this.tablePage.page = 1;
        this.$refs['InputGroupVue'].setFromData(data)
        this.search();
      } else if (e.target.nodeName == "IMG") {
        this.$confirm("此操作将删除该检索方案, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            await remove({ id: val.id });
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.serchSaveList();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    async serchSaveList() {
      let res = await selectDataSchemeList({ queryType: 1 });
      this.serchSaveData = res.data.data;
    },
    handleCommand(command) {
      const { item, type } = command;
      this[type](item);
    },
    // 选项卡切换
    handleClick() {
      this.chongzhi();
    },
    // 预警信息跳转详情
    handelPush(val) {
      val.isRead = "1";
      let routeUrl = this.$router.resolve({
        path: "/index/accurateInfo",
        query: {
          info: encodeURIComponent(
            JSON.stringify({
              id: val.dataId,
              dataId: val.dataId,
            })
          ),
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    // 精简 || 列表
    changeradio() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 300)
    },
    // 舆情调性 全选
    handleCheckAllChange(val) {
      this.checkedTonality = val ? [2, 1, 0] : [];
    },
    // 媒体环节
    handleCheckAllChangess(val) {
      if (val.id === 0) {
        if (val.checked) {
          this.checkedMedia = [];
          this.media.forEach((x) => {
            x.checked = true;
            if (x.id !== 0) {
              this.checkedMedia.push(x);
            }
          });
        } else {
          this.checkedMedia = [];
          this.media.forEach((x) => {
            x.checked = false;
          });
        }
      } else {
        this.checkedMedia = [];
        this.media.forEach((x) => {
          if (x.checked && x.id !== 0) {
            this.checkedMedia.push(x);
          }
        });
        if (this.checkedMedia.length == this.media.length - 1) {
          this.media[0].checked = true;
        } else {
          this.media[0].checked = false;
        }
      }
    },
    // 媒体属性
    handleCheckAllChangesss(val) {
      if (val.id === 0) {
        if (val.checked) {
          this.mediaAttribute = [];
          this.Attribute.forEach((x) => {
            x.checked = true;
            if (x.id !== 0) {
              this.mediaAttribute.push(x);
            }
          });
        } else {
          this.mediaAttribute = [];
          this.Attribute.forEach((x) => {
            x.checked = false;
          });
        }
      } else {
        this.mediaAttribute = [];
        this.Attribute.forEach((x) => {
          if (x.checked && x.id !== 0) {
            this.mediaAttribute.push(x);
          }
        });
        if (this.mediaAttribute.length == this.Attribute.length - 1) {
          this.Attribute[0].checked = true;
        } else {
          this.Attribute[0].checked = false;
        }
      }
    },
    // 媒体级别
    handleCheckAllChangessss(val) {
      if (val.id === 0) {
        if (val.checked) {
          this.Level = [];
          this.mediaLevel.forEach((x) => {
            x.checked = true;
            if (x.id !== 0) {
              this.Level.push(x);
            }
          });
        } else {
          this.Level = [];
          this.mediaLevel.forEach((x) => {
            x.checked = false;
          });
        }
      } else {
        this.Level = [];
        this.mediaLevel.forEach((x) => {
          if (x.checked && x.id !== 0) {
            this.Level.push(x);
          }
        });
        if (this.Level.length == this.mediaLevel.length - 1) {
          this.mediaLevel[0].checked = true;
        } else {
          this.mediaLevel[0].checked = false;
        }
      }
    },
    // 舆情调性
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
    },
    //查看原文
    lookOriginal(item) {
      this.$log({
        operationModule: this.activeName == "videoList" ? "1001102" : "1001101",
        operationDetails: JSON.stringify(item),
        operationRecode: 7,
        requestBody: item.website,
      });
      window.open(item.website);
    },
    // 数据上报
    async dataReport(item) {
      let data = {
        infoType: 1,
        inforMsgId: item.dataId + "",
        organizationId: item.organId,
      };
      const res = await dataReport(data);
      this.$log({
        operationModule: this.activeName == "videoList" ? "1001102" : "1001101",
        operationDetails: JSON.stringify(item),
        operationRecode: 8,
        requestUrl: res.config.baseURL + res.config.url,
        requestMethod: res.config.method,
        requestBody: JSON.stringify({
          infoType: 1,
          inforMsgId: item.dataId + "",
          organizationId: item.organId,
        }),
      });
      if (res != undefined) {
        if (res.data.data.code !== 200) {
          this.$message({
            message: res.data.data.msg,
            type: "warning",
          });
        } else {
          this.$message({
            message: res.data.data.msg,
            type: "success",
          });
        }
      }
      setTimeout(() => {
        this.getList();
      }, 1000);
    },
    // 界面截图
    async screenShot(item) {
      item.disabled = true;
      const res = await screenShot({
        typeStr: "PUBLIC_SENTIMENT",
        infoId: item.accurateId + "",
        orgaId: this.$store.state.user.organizationId,
        uuid: item.screenshotAddress,
        website: item.website,
      });
      this.$log({
        operationModule: this.activeName == "videoList" ? "1001102" : "1001101",
        operationDetails: JSON.stringify(item),
        operationRecode: 3,
        requestUrl: res.config.baseURL + res.config.url,
        requestMethod: res.config.method,
        requestBody: JSON.stringify({
          typeStr: "PUBLIC_SENTIMENT",
          infoId: item.accurateId + "",
          orgaId: this.$store.state.user.organizationId,
          uuid: item.screenshotAddress,
          website: item.website,
        }),
      });
      this.$message({
        message: res.data.data.ResponseMsg,
        type: "success",
      });
    },
    // 重置
    chongzhi() {
      this.tablePage.page = 1;
      this.dataList = {};
      this.repeatData = false;
      this.activeSerchSave = -1;
      this.formInline = {
        region: "title",
        input1: "",
        value: "",
        timer: [],
      };
      this.$refs['InputGroupVue'].clearFromData()
      document.querySelector(".eventing input").value = "";
      this.checkAll = false;
      this.checkedTonality = [];
      this.warning = false;
      this.checkedAreas = "";
      this.checkedMedia = [];
      this.mediaAttribute = [];
      this.Level = [];
      this.regional.forEach((x) => {
        x.checked = false;
      });
      this.media.forEach((x) => {
        x.checked = false;
      });
      this.Attribute.forEach((x) => {
        x.checked = false;
      });
      this.mediaLevel.forEach((x) => {
        x.checked = false;
      });
      this.getList();
    },
    // 详情页
    hangel(item) {
      item.isRead = "1";
      let routeUrl = this.$router.resolve({
        path: "/warningMessage/index/yuqing",
        query: {
          info: encodeURIComponent(
            JSON.stringify({
              id: item.accurateId,
              dataId: item.dataId,
            })
          ),
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    // 复制
    copy(msg) {
      this.$publicFun.customCopy(msg);
      this.$log({
        operationModule: this.activeName == "videoList" ? "1001102" : "1001101",
        operationDetails: JSON.stringify(msg),
        operationRecode: 1,
        requestBody: msg.copy,
      });
    },
    //复制链接与标题
    quickCopy(msg) {
      let str = "";
      str = "标题：" + msg.title + "\n" + "链接：" + msg.website;
      this.$publicFun.copyFun(str);
      this.$log({
        operationModule: this.activeName == "videoList" ? "1001102" : "1001101",
        operationDetails: JSON.stringify(msg),
        operationRecode: 2,
        requestBody: msg.str,
      });
    },
    //复制链接与标题
    quickCopy2(msg) {
      let str = "";
      str = msg.author + msg.website;
      this.$publicFun.copyFun(str);
      this.$log({
        operationModule: this.activeName == "videoList" ? "1001102" : "1001101",
        operationDetails: JSON.stringify(msg),
        operationRecode: 2,
        requestBody: msg.str,
      });
    },
    myCopy(msg, type) {
      let str = "";
      switch (type) {
        case 1:
          str = "链接：" + msg.website;
          break;
        case 2:
          str = "标题：" + msg.title;
          break;
      }
      this.$publicFun.copyFun(str);
      this.$log({
        operationModule: this.activeName == "videoList" ? "1001102" : "1001101",
        operationDetails: JSON.stringify(msg),
        operationRecode: 19,
        requestBody: str,
      });
    },
    // 删除
    deletes(item) {
      item.message = "这篇新闻";
      this.$refs.openDialog.openDialog(item);
    },
    async deleteFn(item) {
      const res = await deleteAccurate({
        accurateId: item.accurateId + "",
        dataId: item.dataId + "",
        organId: this.$store.state.user.organizationId,
      });
      this.$log({
        operationModule: this.activeName == "videoList" ? "1001102" : "1001101",
        operationDetails: JSON.stringify(item),
        operationRecode: 5,
        requestUrl: res.config.baseURL + res.config.url,
        requestMethod: res.config.method,
        requestBody: JSON.stringify({
          accurateId: item.accurateId + "",
          dataId: item.dataId + "",
          organId: this.$store.state.user.organizationId,
        }),
      });
      if (res.data.code == 200) {
        this.$message({
          message: "删除成功！",
          type: "success",
        });
        setTimeout(() => {
          this.getList();
        }, 1500);
        if (this.textData.length == 1) {
          if (this.tablePage.page > 1) {
            this.tablePage.page = this.tablePage.page - 1;
          }
        }
      } else {
        this.$message.error("删除失败！");
      }
    },
    // 展开 收缩 头部
    zhankaiChange() {
      this.zhankai = !this.zhankai;
    },
    // 时间框获得焦点
    focusDate() {
      this.formInline.timer = [];
      this.formInline.timer[0] =
        this.$calcu.timeCycle(new Date(), "yyyy-MM-dd") + " " + "00:00:00";
      this.formInline.timer[1] = this.$calcu.timeCycle(
        new Date(),
        "yyyy-MM-dd HH:mm:ss"
      );
    },
    style() {
      let style = "";
      let height = document.documentElement.clientHeight;
      style = `height:${height}px`;
      return style;
    },
    search() {
      let data = {
        isEarlyWarn: 1
      };
      if (
        this.formInline.timer[0] &&
        this.formInline.timer[1] &&
        this.formInline.timer[0] >= this.formInline.timer[1]
      ) {
        this.$message.warning("请选择正确的时间段");
        return;
      }
      if (!this.formInline.timer[0] || !this.formInline.timer[1]) {
        this.formInline.timer = [];
      }
      //是否隐藏重复数据
      if (this.repeatData) {
        data.dataExist = 0;
      }
      //舆情调性
      if (this.checkedTonality.length > 0 && !this.checkAll) {
        data.tonalState = this.checkedTonality.join(",");
      }
      // 媒体环节
      if (
        this.checkedMedia.length > 0 &&
        this.checkedMedia.length != this.media.length - 1
      ) {
        let checkedMedia = [];
        this.checkedMedia.forEach((x) => {
          checkedMedia.push(x.mediaClassifyName);
        });
        data.mediaLink = checkedMedia.join(",");
      }
      //媒体属性
      if (
        this.mediaAttribute.length > 0 &&
        this.mediaAttribute.length != this.Attribute.length - 1
      ) {
        let mediaAttribute = [];
        this.mediaAttribute.forEach((x) => {
          mediaAttribute.push(x.mediaClassifyName);
        });
        data.mediaAttribute = mediaAttribute.join(",");
      }
      //媒体级别
      if (
        this.Level.length > 0 &&
        this.Level.length != this.mediaLevel.length - 1
      ) {
        let Level = [];
        this.Level.forEach((x) => {
          Level.push(x.mediaClassifyName);
        });
        data.mediaLevel = Level.join(",");
      }
      data = { ...data, ...this.$refs.InputGroupVue.getFromData() }
      // 时间
      if (
        this.formInline.timer != false &&
        this.formInline.timer[0] &&
        this.formInline.timer[1]
      ) {
        //时间
        data.startTimeStr =
          this.formInline.timer[0].replace(" ", "T") + "+08:00";
        data.endTimeStr = this.formInline.timer[1].replace(" ", "T") + "+08:00";
      }
      // 事件 formInline.value
      if (this.formInline.value.length != 0) {
        data.eventClass =
          this.formInline.value[this.formInline.value.length - 1].eventName;
      }
      this.tablePage.page = 1;
      this.getList(data);
    },
    //溯源
    async Traceability() {
      if (
        this.formInline.timer.length == 0 &&
        this.warning == false &&
        this.repeatData == false &&
        this.checkedTonality.length == 0 &&
        this.checkAll == false &&
        this.checkedAreas == "" &&
        this.checkedMedia.length == 0 &&
        this.mediaAttribute.length == 0 &&
        this.Level.length == 0 &&
        this.formInline.input1 == "" &&
        this.formInline.value.length == 0
      ) {
        this.$message.warning("请选最少选择一项筛选条件！");
      } else {
        this.loading = true;
        let data = {
          organId: this.$store.state.user.organizationId,
          pageSize: 1,
          pageNum: 1,
          startTimeStr: this.$calcu.calcuTime(30) + "T00:00:00+08:00",
          endTimeStr:
            this.$calcu.timeCycle(new Date(), "yyyy-MM-dd") + "T23:59:59+08:00",
          dataTraceability: 1,
        };
        if (
          this.formInline.timer[0] &&
          this.formInline.timer[1] &&
          this.formInline.timer[0] >= this.formInline.timer[1]
        ) {
          this.$message.warning("请选择正确的时间段");
          return;
        }
        if (!this.formInline.timer[0] || !this.formInline.timer[1]) {
          this.formInline.timer = [];
        }
        if (this.warning) {
          data.isEarlyWarn = 1;
        }
        if (this.repeatData) {
          data.dataExist = 0;
        }
        //舆情调性
        if (this.checkedTonality.length > 0 && !this.checkAll) {
          data.tonalState = this.checkedTonality.join(",");
        }
        // 监测区域
        if (this.checkedAreas != -1) {
          data.areaInvolved = this.checkedAreas;
        }
        // 媒体环节
        if (
          this.checkedMedia.length > 0 &&
          this.checkedMedia.length != this.media.length - 1
        ) {
          let checkedMedia = [];
          this.checkedMedia.forEach((x) => {
            checkedMedia.push(x.mediaClassifyName);
          });
          data.mediaLink = checkedMedia.join(",");
        }
        //媒体属性
        if (
          this.mediaAttribute.length > 0 &&
          this.mediaAttribute.length != this.Attribute.length - 1
        ) {
          let mediaAttribute = [];
          this.mediaAttribute.forEach((x) => {
            mediaAttribute.push(x.mediaClassifyName);
          });
          data.mediaAttribute = mediaAttribute.join(",");
        }
        //媒体级别
        if (
          this.Level.length > 0 &&
          this.Level.length != this.mediaLevel.length - 1
        ) {
          let Level = [];
          this.Level.forEach((x) => {
            Level.push(x.mediaClassifyName);
          });
          data.mediaLevel = Level.join(",");
        }
        //标题 正文  作者 region
        if (this.formInline.input1 != "") {
          data[this.formInline.region] = this.formInline.input1;
        }
        // 时间
        if (
          this.formInline.timer != false &&
          this.formInline.timer[0] &&
          this.formInline.timer[1]
        ) {
          //时间
          data.startTimeStr =
            this.formInline.timer[0].replace(" ", "T") + "+08:00";
          data.endTimeStr =
            this.formInline.timer[1].replace(" ", "T") + "+08:00";
        }
        // 事件 formInline.value
        if (this.formInline.value.length != 0) {
          data.eventClass =
            this.formInline.value[this.formInline.value.length - 1].eventName;
        }
        const res = await accurateList(data);
        if (res.data.data) {
          if (res.data.data.result) {
            this.textData = res.data.data.result;
            this.tablePage.total = 1;
            this.textData.forEach((item) => {
              item.accurateId = item.accurateId + "";
              item.dataId = item.dataId + "";
              item.releaseTime = item.releaseTime
                .slice(0, 19)
                .replace("T", " ");
              if (item.eventClass) {
                item.eventList = item.eventClass.split(" ");
                let reg = new RegExp(" ", "g"); //g代表全部
                item.eventClass = item.eventClass.replace(reg, "<br/>");
              } else {
                item.eventList = [];
              }
              if (item.keyword) {
                item.keyword = item.keyword.replace(/<br\/>/g, " ");
                item.keywordList = item.keyword.split(" ");
                item.keywordArry = item.keyword.split(" ");
                if (item.keywordList.length > 3) {
                  item.keywordList = item.keywordList.slice(0, 3);
                }
                let reg = new RegExp(" ", "g"); //g代表全部
                item.keyword = item.keyword.replace(reg, "/");
              } else {
                item.keywordList = [];
                item.keywordArry = [];
              }
            });
            let arrList = [];
            let idList = [];
            this.biaoqianList.forEach((item) => {
              arrList.push(item.labelId);
            });
            this.textData.forEach((item) => {
              if (item.setLabel) {
                if (arrList.indexOf(item.setLabel) == -1) {
                  idList.push(item.setLabel);
                }
              }
            });
            idList.forEach((item) => {
              this.textData.forEach((ite) => {
                if (ite.setLabel == item) {
                  ite.setLabel = null;
                }
              });
            });
          }
        }
        if (res.data.code == 200 && data.pageNum == 1) {
          this.newDataSearch = JSON.parse(JSON.stringify(data));
          this.newDataSearch.pageSize = 10;
          this.newDataSearch.pageNum = 1;
        }
        this.loading = false;
      }
    },
    // 获取列表
    async getList(obj) {
      this.loading = true;
      let data = {
        organId: this.$store.state.user.organizationId,
        pageSize: this.tablePage.pageSize,
        pageNum: this.tablePage.page,
        startTimeStr: this.$calcu.calcuTime(30) + "T00:00:00+08:00",
        endTimeStr:
          this.$calcu.timeCycle(new Date(), "yyyy-MM-dd") + "T23:59:59+08:00",
        isEarlyWarn: 1
      };
      if (obj) {
        this.dataList = obj;
        this.dataList.organId = this.$store.state.user.organizationId;
        this.dataList.pageSize = this.tablePage.pageSize;
        this.dataList.pageNum = this.tablePage.page;
        if (this.dataList.startTimeStr) {
          data.startTimeStr = this.dataList.startTimeStr;
        } else {
          this.dataList.startTimeStr = data.startTimeStr;
        }
        if (this.dataList.endTimeStr) {
          data.endTimeStr = this.dataList.endTimeStr;
        } else {
          this.dataList.endTimeStr = data.endTimeStr;
        }
        data = this.dataList;
      } else if (Object.values(this.dataList).length > 0) {
        data = this.dataList;
        data.pageSize = this.tablePage.pageSize;
        data.pageNum = this.tablePage.page;
        data.organId = this.$store.state.user.organizationId;
      }
      if (this.activeName == "videoList") {
        data.videoListFlag = true;
      }
      const res = await accurateList(data);
      if (res.data.data) {
        if (res.data.data.result) {
          this.tablePage.total = res.data.data.total;
          res.data.data.result.forEach((item) => {
            item.accurateId = item.accurateId + "";
            item.dataId = item.dataId + "";
            item.releaseTime = item.releaseTime.slice(0, 19).replace("T", " ");
            if (item.eventClass) {
              item.eventList = item.eventClass.split(" ");
              let reg = new RegExp(" ", "g"); //g代表全部
              item.eventClass = item.eventClass.replace(reg, "<br/>");
            } else {
              item.eventList = [];
            }
            if (item.keyword) {
              item.keyword = item.keyword.replace(/<br\/>/g, " ");
              item.keywordList = item.keyword.split(" ");
              item.keywordArry = item.keyword.split(" ");
              if (item.keywordList.length > 3) {
                item.keywordList = item.keywordList.slice(0, 3);
              }
              let reg = new RegExp(" ", "g"); //g代表全部
              item.keyword = item.keyword.replace(reg, "/");
            } else {
              item.keywordList = [];
              item.keywordArry = [];
            }
          });
          this.textData = res.data.data.result;
          let arrList = [];
          let idList = [];
          this.biaoqianList.forEach((item) => {
            arrList.push(item.labelId);
          });
          this.textData.forEach((item) => {
            if (item.setLabel) {
              if (arrList.indexOf(item.setLabel) == -1) {
                idList.push(item.setLabel);
              }
            }
          });
          idList.forEach((item) => {
            this.textData.forEach((ite) => {
              if (ite.setLabel == item) {
                ite.setLabel = null;
              }
            });
          });
        }
      }
      this.tablePage.page++;
      this.loading = false;
    },
    //下拉加载
    async load() {
      if (this.loading2) {
        return false
      }
      this.loading2 = true;
      let data = {
        organId: this.$store.state.user.organizationId,
        pageSize: this.tablePage.pageSize,
        pageNum: this.tablePage.page,
        startTimeStr: this.$calcu.calcuTime(30) + "T00:00:00+08:00",
        endTimeStr:
          this.$calcu.timeCycle(new Date(), "yyyy-MM-dd") + "T23:59:59+08:00",
        isEarlyWarn: 1
      };
      //是否隐藏重复数据
      if (this.repeatData) {
        data.dataExist = 0;
      }
      //舆情调性
      if (this.checkedTonality.length > 0 && !this.checkAll) {
        data.tonalState = this.checkedTonality.join(",");
      }
      // 媒体环节
      if (
        this.checkedMedia.length > 0 &&
        this.checkedMedia.length != this.media.length - 1
      ) {
        let checkedMedia = [];
        this.checkedMedia.forEach((x) => {
          checkedMedia.push(x.mediaClassifyName);
        });
        data.mediaLink = checkedMedia.join(",");
      }
      //媒体属性
      if (
        this.mediaAttribute.length > 0 &&
        this.mediaAttribute.length != this.Attribute.length - 1
      ) {
        let mediaAttribute = [];
        this.mediaAttribute.forEach((x) => {
          mediaAttribute.push(x.mediaClassifyName);
        });
        data.mediaAttribute = mediaAttribute.join(",");
      }
      //媒体级别
      if (
        this.Level.length > 0 &&
        this.Level.length != this.mediaLevel.length - 1
      ) {
        let Level = [];
        this.Level.forEach((x) => {
          Level.push(x.mediaClassifyName);
        });
        data.mediaLevel = Level.join(",");
      }
      data = { ...data, ...this.$refs.InputGroupVue.getFromData() }
      // 时间
      if (
        this.formInline.timer != false &&
        this.formInline.timer[0] &&
        this.formInline.timer[1]
      ) {
        //时间
        data.startTimeStr =
          this.formInline.timer[0].replace(" ", "T") + "+08:00";
        data.endTimeStr = this.formInline.timer[1].replace(" ", "T") + "+08:00";
      }
      // 事件 formInline.value
      if (this.formInline.value.length != 0) {
        data.eventClass =
          this.formInline.value[this.formInline.value.length - 1].eventName;
      }
      const res = await accurateList(data);
      if (res.data.data) {
        if (res.data.data.result) {
          this.tablePage.total = res.data.data.total;
          res.data.data.result.forEach((item) => {
            item.accurateId = item.accurateId + "";
            item.dataId = item.dataId + "";
            item.releaseTime = item.releaseTime.slice(0, 19).replace("T", " ");
            if (item.eventClass) {
              item.eventList = item.eventClass.split(" ");
              let reg = new RegExp(" ", "g"); //g代表全部
              item.eventClass = item.eventClass.replace(reg, "<br/>");
            } else {
              item.eventList = [];
            }
            if (item.keyword) {
              item.keyword = item.keyword.replace(/<br\/>/g, " ");
              item.keywordList = item.keyword.split(" ");
              item.keywordArry = item.keyword.split(" ");
              if (item.keywordList.length > 3) {
                item.keywordList = item.keywordList.slice(0, 3);
              }
              let reg = new RegExp(" ", "g"); //g代表全部
              item.keyword = item.keyword.replace(reg, "/");
            } else {
              item.keywordList = [];
              item.keywordArry = [];
            }
            this.textData.push(item);
          });
          let arrList = [];
          let idList = [];
          this.biaoqianList.forEach((item) => {
            arrList.push(item.labelId);
          });
          this.textData.forEach((item) => {
            if (item.setLabel) {
              if (arrList.indexOf(item.setLabel) == -1) {
                idList.push(item.setLabel);
              }
            }
          });
          idList.forEach((item) => {
            this.textData.forEach((ite) => {
              if (ite.setLabel == item) {
                ite.setLabel = null;
              }
            });
          });
        }
      }
      this.tablePage.page++;
      this.loading2 = false;
    },
    // 收藏
    async editShouc(item) {
      let res = await sentimentEdit({
        accurateId: item.accurateId + "",
        organId: item.organId,
        isCollect: item.isCollect === "0" ? "1" : "0",
      });
      this.$log({
        operationModule: this.activeName == "videoList" ? "1001102" : "1001101",
        operationDetails: JSON.stringify(item),
        operationRecode: 4,
        requestUrl: res.config.baseURL + res.config.url,
        requestMethod: res.config.method,
        requestBody: JSON.stringify({
          accurateId: item.accurateId + "",
          organId: item.organId,
          isCollect: item.isCollect === "0" ? "1" : "0",
        }),
      });
      if (res != undefined) {
        this.$message({
          message: res.data.msg,
          type: "success",
        });
        item.isCollect = item.isCollect === "0" ? "1" : "0";
      }
    },
    // 修改标签
    async changeLabel(val) {
      // 取消标签
      if (val.setLabel == -1) {
        const res = await deleteLabel({
          accurateId: val.accurateId + "",
        });
        this.$log({
          operationModule: "1001101",
          operationDetails: JSON.stringify(val),
          operationRecode: 9,
          requestUrl: res.config.baseURL + res.config.url,
          requestMethod: res.config.method,
          requestBody: JSON.stringify({
            accurateId: val.accurateId + "",
          }),
        });
        if (res.data.code == 200) {
          this.$message({
            message: "操作成功,已取消标签！",
            type: "success",
          });
          setTimeout(() => {
            this.getList();
          }, 2000);
        } else {
          this.$message.error("操作失败，请稍后再试!");
        }

        return;
      }
      // 修改标签
      let res = await modifyLabel({
        dataId: val.dataId + "",
        setLabel: val.setLabel,
        organId: this.$store.state.user.organizationId,
      });
      this.$log({
        operationModule: "1001101",
        operationDetails: JSON.stringify(val),
        operationRecode: 9,
        requestUrl: res.config.baseURL + res.config.url,
        requestMethod: res.config.method,
        requestBody: JSON.stringify({
          dataId: val.dataId + "",
          setLabel: val.setLabel,
          organId: this.$store.state.user.organizationId,
        }),
      });
      if (res.data.code == 200) {
        this.$message({
          message: "修改标签成功！",
          type: "success",
        });
      } else {
        this.$message.error("修改标签失败！");
      }
    },
    // 序号
    indexMethod(index) {
      return (
        index +
        this.tablePage.page * this.tablePage.pageSize -
        this.tablePage.pageSize +
        1
      );
    },
    //自动更新
    async autoUpdate() {
      const list = JSON.parse(sessionStorage.getItem("list"))[0];
      let data = {
        organId: this.$store.state.user.organizationId,
        pageSize: 1,
        pageNum: 1,
        startTimeStr: this.$calcu.calcuTime(30) + "T00:00:00+08:00",
        endTimeStr:
          this.$calcu.timeCycle(new Date(), "yyyy-MM-dd") + "T23:59:59+08:00",
        isEarlyWarn: 1
      };
      //是否隐藏重复数据
      if (this.repeatData) {
        data.dataExist = 0;
      }
      //舆情调性
      if (this.checkedTonality.length > 0 && !this.checkAll) {
        data.tonalState = this.checkedTonality.join(",");
      }
      // 媒体环节
      if (
        this.checkedMedia.length > 0 &&
        this.checkedMedia.length != this.media.length - 1
      ) {
        let checkedMedia = [];
        this.checkedMedia.forEach((x) => {
          checkedMedia.push(x.mediaClassifyName);
        });
        data.mediaLink = checkedMedia.join(",");
      }
      //媒体属性
      if (
        this.mediaAttribute.length > 0 &&
        this.mediaAttribute.length != this.Attribute.length - 1
      ) {
        let mediaAttribute = [];
        this.mediaAttribute.forEach((x) => {
          mediaAttribute.push(x.mediaClassifyName);
        });
        data.mediaAttribute = mediaAttribute.join(",");
      }
      //媒体级别
      if (
        this.Level.length > 0 &&
        this.Level.length != this.mediaLevel.length - 1
      ) {
        let Level = [];
        this.Level.forEach((x) => {
          Level.push(x.mediaClassifyName);
        });
        data.mediaLevel = Level.join(",");
      }
      data = { ...data, ...this.$refs.InputGroupVue.getFromData() }
      // 时间
      if (
        this.formInline.timer != false &&
        this.formInline.timer[0] &&
        this.formInline.timer[1]
      ) {
        //时间
        data.startTimeStr =
          this.formInline.timer[0].replace(" ", "T") + "+08:00";
        data.endTimeStr = this.formInline.timer[1].replace(" ", "T") + "+08:00";
      }
      // 事件 formInline.value
      if (this.formInline.value.length != 0) {
        data.eventClass =
          this.formInline.value[this.formInline.value.length - 1].eventName;
      }
      const res = await accurateList(data);
      if (res.data.data) {
        if (res.data.data.result && list.dataId === res.data.data.result[0].dataId) {
          res.data.data.result.forEach((item) => {
            item.accurateId = item.accurateId + "";
            item.dataId = item.dataId + "";
            item.releaseTime = item.releaseTime.slice(0, 19).replace("T", " ");
            item.newData = true;
            if (item.eventClass) {
              item.eventList = item.eventClass.split(" ");
              let reg = new RegExp(" ", "g"); //g代表全部
              item.eventClass = item.eventClass.replace(reg, "<br/>");
            } else {
              item.eventList = [];
            }
            if (item.keyword) {
              item.keyword = item.keyword.replace(/<br\/>/g, " ");
              item.keywordList = item.keyword.split(" ");
              item.keywordArry = item.keyword.split(" ");
              if (item.keywordList.length > 3) {
                item.keywordList = item.keywordList.slice(0, 3);
              }
              let reg = new RegExp(" ", "g"); //g代表全部
              item.keyword = item.keyword.replace(reg, "/");
            } else {
              item.keywordList = [];
              item.keywordArry = [];
            }
            this.textData.unshift(item);
          });
          let arrList = [];
          let idList = [];
          this.biaoqianList.forEach((item) => {
            arrList.push(item.labelId);
          });
          if (this.textData[0].setLabel) {
            if (arrList.indexOf(this.textData[0].setLabel) == -1) {
              idList.push(this.textData[0].setLabel);
            }
          }
          idList.forEach((item) => {
            if (this.textData[0].setLabel == item) {
              this.textData[0].setLabel = null;
            }
          });
        }
      }
    },
    // 新数据转旧数据
    oldData(item) {
      item.newData = false;
    }
  },
  mounted() {
    this.loading = true;
    window.addEventListener("setItem", () => { this.autoUpdate() });
    //从新版大屏跳转过来的
    if (this.$route.query.toType == 2) {//当天数据
      this.formInline.timer = [this.$calcu.timeCycle(new Date(), "yyyy-MM-dd") + " " + "00:00:00", this.$calcu.timeCycle(new Date(), "yyyy-MM-dd") + " " + "23:59:59"]
      this.search()
    }
    if (this.$route.query.toType == 3) {//当天负面数据
      this.checkedTonality = [2]
      this.formInline.timer = [this.$calcu.timeCycle(new Date(), "yyyy-MM-dd") + " " + "00:00:00", this.$calcu.timeCycle(new Date(), "yyyy-MM-dd") + " " + "23:59:59"]
      this.search()
    }
    if (this.$route.query.toType == 4) {//当天预警数据
      this.warning = true
      this.formInline.timer = [this.$calcu.timeCycle(new Date(), "yyyy-MM-dd") + " " + "00:00:00", this.$calcu.timeCycle(new Date(), "yyyy-MM-dd") + " " + "23:59:59"]
      this.search()
    }
  },
  created() {
    this.$http
      .all([
        selectDataSchemeList({ queryType: 1 }),
        setLabel({
          organizationId: this.$store.state.user.organizationId,
          noShow: 1,
        }),
        conditionList({
          areaId: this.$store.state.user.areaId,
          organizationId: this.$store.state.user.organizationId,
        }),
      ])
      .then((res) => {
        //获取标签
        if (
          res[1].data.code == 200 &&
          res[1].data.rows &&
          res[1].data.rows.length > 0
        ) {
          this.biaoqianList = res[1].data.rows;
          if (this.biaoqianList.length == 1) {
            this.biaoqianList = [];
          }
        }
        //筛选条件列表
        if (res[2] != undefined) {
          if (res[2].data.data) {
            res[2].data.data.regional.forEach((item, index) => {
              if (item.areaId == 0) {
                res[2].data.data.regional.splice(index, 1);
              }
            });
            this.regional = res[2].data.data.regional;
          }
          res[2].data.data.media[0].mediaClassifyName = "全选";
          res[2].data.data.media.map((x) => {
            return (x.checked = false);
          });
          res[2].data.data.mediaLink[0].mediaClassifyName = "全选";
          res[2].data.data.mediaLink.map((x) => {
            return (x.checked = false);
          });
          res[2].data.data.mediaLevel[0].mediaClassifyName = "全选";
          res[2].data.data.mediaLevel.map((x) => {
            return (x.checked = false);
          });
          this.media = res[2].data.data.mediaLink;
          this.Attribute = res[2].data.data.media;
          this.mediaLevel = res[2].data.data.mediaLevel;
        }
        if (this.$route.query.info == "todayYJ") {
          this.formInline.timer[0] = this.$calcu.calcuTime(0) + " 00:00:00";
          this.formInline.timer[1] = this.$calcu.calcuTime(0) + " 23:59:59";
          this.checkedTonality = [2];
          this.$router.push({ query: {} });
          this.search();
        } else if (this.$route.query.info == "todayMG") {
          this.formInline.timer[0] = this.$calcu.calcuTime(0) + " 00:00:00";
          this.formInline.timer[1] = this.$calcu.calcuTime(0) + " 23:59:59";
          this.warning = true;
          this.$router.push({ query: {} });
          this.search();
        } else if (this.$route.query.info == "today") {
          this.formInline.timer[0] = this.$calcu.calcuTime(0) + " 00:00:00";
          this.formInline.timer[1] = this.$calcu.calcuTime(0) + " 23:59:59";
          this.$router.push({ query: {} });
          this.search();
        } else if (
          this.$route.query.info == "东坡区" ||
          this.$route.query.info == "彭山区" ||
          this.$route.query.info == "仁寿县" ||
          this.$route.query.info == "洪雅县" ||
          this.$route.query.info == "丹棱县" ||
          this.$route.query.info == "青神县" ||
          this.$route.query.info == "天府新区眉山"
        ) {
          this.formInline.timer[0] = this.$calcu.calcuTime(0) + " 00:00:00";
          this.formInline.timer[1] = this.$calcu.calcuTime(0) + " 23:59:59";
          let area = this.$route.query.info;
          this.checkedTonality = [2];
          this.regional.forEach((x) => {
            if (x.placeName == area) {
              this.checkedAreas = x.areaId;
            }
          });
          this.$router.push({ query: {} });
          this.search();
        } else if (this.$route.query.info) {
          this.dataList = JSON.parse(
            decodeURIComponent(this.$route.query.info)
          );
          this.$router.push({ query: {} });
          this.getList();
        } else {
          let ifPlan = false;
          this.serchSaveData = res[0].data.data;
          this.serchSaveData.forEach((x, i) => {
            if (x.isDefault == 1) {
              ifPlan = true;
              this.isDefault = x.id;
              this.planYes(
                { target: { nodeName: "DIV", className: "plan-tab-li" } },
                x,
                i
              );
            }
          });
          if (!ifPlan) {
            this.getList();
          }
        }
      });
  },
  components: {
    deleteDialog, InputGroupVue
  },
  beforeDestroy() {
    window.removeEventListener("storage", () => { this.autoUpdate() });
  }
};
</script>

<style scoped>
::v-deep .bottom-operation .el-input__inner {
  border-color: #000 !important;
}

::v-deep .el-drawer__body {
  overflow: scroll;
}

.el-tabs {
  height: 40px;
}

.index-ul {
  width: 100%;
  height: 100%;
  padding: 16px;
}

.index-ul-li {
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px;
  cursor: pointer;
}

.index-ul-li-box {
  display: flex;
  align-items: center;
}

.index-ul-li:hover {
  background: #f4f7fd;
}

.index-ul-li img {
  margin-right: 16px;
  position: relative;
  top: 1px;
}

.index-ul-li-title {
  line-height: 24px;
  width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: #5a6474;
  margin-right: 30px;
}

.index-ul-li-comment {
  width: 70px;
  font-size: 14px;
  color: #5a6474;
  margin-right: 38px;
}

.index-ul-li-comment span {
  color: #2e59ec;
}

.index-ul-li-time {
  font-size: 14px;
  color: #5a6474;
}

::v-deep .el-drawer {
  overflow: visible;
}

::v-deep .el-drawer__close-btn {
  display: none;
}

::v-deep .el-drawer__header {
  font-size: 18px;
  font-weight: bold;
  color: #1a1a1a;
}

.drawer-box {
  position: fixed;
  right: 0px;
  width: 24px;
  height: 100px;
  background-image: url("../../assets/img/views/lakai.png");
  background-repeat: no-repeat;
  bottom: calc(100% - 600px);
  cursor: pointer;
}

.drawer-box2 {
  z-index: 999;
  position: fixed;
  width: 24px;
  height: 100px;
  right: 636px;
  background-image: url("../../assets/img/views/guanbikuang.png");
  background-repeat: no-repeat;
  bottom: calc(100% - 600px);
  cursor: pointer;
  transition: all 0.3s ease;
}

.M-L {
  margin-left: 10px;
}

::v-deep .el-radio-button--medium .el-radio-button__inner {
  padding: 6px 8px;
}

.message-count .message-yuqing,
.message-count .message-region,
.message-count .message-link {
  min-height: 58px;
  display: flex;
  line-height: 52px;
  width: 100%;
}

.message-region .el-radio {
  display: flex;
  min-height: 58px;
  line-height: 52px;
  align-items: center;
}

.message-count {
  padding: 24px 24px 0 24px;
  overflow-y: auto;
  height: 100%;
}

.message-count .message-top {
  padding: 0 24px;
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 24px;
}

.message-count .message-yuqing {
  display: flex;
  justify-content: space-between;
  line-height: 52px;
  width: 100%;
}

.message-count .message-link {
  display: flex;
  line-height: 52px;
  width: 100%;
}

.message-count .message-region {
  display: flex;
  line-height: 52px;
  width: 100%;
}

.message-count .message-time {
  width: 100%;
  padding: 16px 0;
}

.message-count .message-top-title {
  min-width: 56px;
  padding-top: 1px;
  margin-top: 16px;
  margin-right: 40px;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  font-weight: bold;
  color: #999999;
}

.message-count .huanjie {
  margin-right: 40px;
}

.message-count .el-checkbox-group {
  display: inline-block;
  height: 100%;
}

.message-count .el-checkbox .el-checkbox__input {
  width: 14px;
  height: 14px;
}

.message-count .demo-form-inline {
  height: 100%;
}

.message-count .select-item,
.el-select .select {
  margin: 0;
  width: 80px;
  height: 36px;
}

.message-count .input {
  width: 240px;
  margin-left: 32px;
}

.message-count .el_font {
  /* margin-left: 32px; */
  margin-right: 12px;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
}

.message-count .message-bottom {
  padding-bottom: 20px;
  margin-top: 16px;
  width: 100%;
  border-radius: 4px;
  background-color: #fff;
}

.bottom-count {
  display: flex;
  padding: 28px 40px;
  border-bottom: 1px solid #e4e6ec;
  transition: all 0.5s linear;
}

.bottom-count:hover {
  box-shadow: 0px 2px 4px 4px rgb(0 0 0 / 6%);
}

.new-data {
  background-color: rgba(247, 69, 84, 0.1);
  transition: all 0s linear;
}

.video-box {
  display: flex;
  flex-wrap: wrap;
  /* justify-content:space-between; */
}

.video-card {
  width: calc(calc(100% / 5) - 16px);
  height: auto;
  background: #ffffff;
  margin-bottom: 16px;
  padding: 16px;
  margin: 0 8px 16px 8px;
  position: relative;
  cursor: pointer;
}

.video-card-status {
  position: absolute;
  top: 21px;
  left: 21px;
  z-index: 8;
}

.video-head {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.bottom-count-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bottom-jingjian-card {
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: space-between;
}

.bottom-jingjian-card-L {
  display: flex;
}

.bottom-jingjian-card-R {
  display: flex;
  font-size: 14px;
}

.bottom-line {
  margin-top: 24px;
  width: 100%;
  height: 1px;
  background: #e4e6ec;
}

.bottom-title {
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 900px;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
}

.bottom-title2 {
  max-width: 266px;
}

.bottom-count-text span {
  display: inline-block;
}

.bottom-count-text {
  display: inline-block;
  padding-left: 40px;
  width: 100%;
}

.my-caozuo {
  margin-left: 40px;
}

.type {
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  width: 96px;
  height: 28px;
  line-height: 28px;
  border-radius: 1px;
}

.analyse-container {
  width: 100%;
  height: 450px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 16px;
  text-align: center;
}

.original {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  color: #2e59ec;
  margin-left: 16px;
}

.original img {
  position: relative;
  top: 4px;
}

.analyse-container-img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.analyse-container-img p {
  font-size: 14px;
  color: #999999;
}

.type:nth-child(2) {
  top: 115px;
}

.website {
  margin-top: 24px;
  width: 96px;
  height: 28px;
  background: #868bff;
  border-radius: 1px;
}

.publics {
  font-size: 14px;
  /* color: #888; */
  color: #000;
}

.bottom-text {
  cursor: pointer;
  overflow: hidden;
  /* line-height: 20px;
  height: 40px;
  font-size: 14px; */
  line-height: 30px;
  height: 66px;
  font-size: 18px;
  color: #444;
  margin-top: 16px;
}

.bottom-meiti {
  font-size: 14px;
  /* color: #888; */
  color: #000;
}

.gengduo-link {
  cursor: pointer;
  color: #2e59ec;
  margin-left: 5px;
}

.message-yuqing .el-checkbox,
.message-link .el-checkbox {
  margin-right: 48px;
  display: inline-block;
}

.yujing {
  margin-left: 32px;
}

.comment-weidu {
  color: #ea3342;
  display: flex;
  align-items: center;
}

.bottom-comment {
  font-size: 14px;
}

.grey {
  color: #a6a9ad;
  pointer-events: none;
}

.comment-pinglun {
  margin-right: 16px;
  margin-left: 16px;
}

.comment-xiangsi {
  margin-right: 24px;
}

.bottom-operation {
  justify-content: space-between;
  display: flex;
  margin-top: 16px;
}

.bottom-operation img {
  width: 16px;
  height: 16px;
}

.weidu {
  margin-left: 5px;
  color: #ea3342;
}

.yidu {
  color: #02bc7c;
}

.yishoucang {
  color: #ff9000;
}

.yiyujing {
  color: #ea3342;
}

.comment-weidu img {
  width: 16px;
  height: 12px;
}

.comment-weidu span {
  margin-left: 5px;
}

.bottom-comment-box {
  padding-right: 17px;
  justify-content: space-between;
  display: flex;
}

.bottom-text-box {
  padding-right: 17px;
  display: flex;
  justify-content: space-between;
}

.bottom-text-caozuo {
  margin-top: 16px;
  display: flex;
  align-items: center;
}

.bottom-text-caozuo>span:not(:first-child) {
  margin-left: 40px;
}

.operation-box {
  display: flex;
  margin-right: 16px;
  font-size: 14px;
  color: #333333;
  line-height: 20px;
}

.operation-box>div span:nth-child(1):nth-child(1) {
  position: relative;
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.operation-box>div span:nth-child(1) span {
  position: absolute;
  top: 3px;
  left: 0;
}

.comment-box-yujing {
  width: 60px;
  margin-right: 16px;
  padding: 2px 8px;
  font-size: 14px;
  font-weight: bold;
  background: #ffedef;
  color: #ea3342;
}

.comment-box-shibie {
  margin-right: 16px;
  width: auto;
  padding: 2px 8px;
  font-size: 14px;
  font-weight: bold;
  background: #eeeeee;
  color: #333333;
}

.line {
  width: 100%;
  height: 1px;
  background: #e4e6ec;
}

.zhankai-change {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.zhankai-change span {
  display: flex;
  align-items: center;
}

.xia-span {
  font-size: 14px;
  color: #2e59ec;
  width: 41px;
}

.xia-span-img {
  transform: rotate(-90deg);
}

.chongzhi {
  color: #333333;
  width: 60px;
  font-size: 14px;
  height: 32px;
  box-sizing: border-box;
}

.animation {
  transition: all 0.7s;
}

.defultHeight {
  height: 0px;
  overflow: hidden;
}

.shouHeight {
  overflow: hidden;
}

.styleMargin {
  margin-right: 5px;
}

.source {
  max-width: 370px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bottom-time {
  margin-top: 16px;
}

.refresh {
  font-size: 14px;
  color: #333333;
  box-sizing: border-box;
  height: 32px;
}

.el-date-editor {
  width: 190px;
}

.fade-enter-active {
  transition: height 0.25s;
}

.fade-enter {
  height: 0;
}

.message_newData {
  color: #2e59ec;
  width: 190px;
  text-align: center;
  font-size: 12px;
  line-height: 26px;
  background-color: #fff;
  border-radius: 13px;
  height: 26px;
  margin: -3px auto 0;
}

.message_newData span {
  text-decoration: underline;
  cursor: pointer;
}

.fade-enter-active {
  transition: height 0.25s;
}

.fade-enter {
  height: 0;
}

.zb_sentiment_copy {
  width: 20px;
  height: 20px;
  vertical-align: bottom;
  margin-left: 5px;
  cursor: pointer;
}

.serch-row {
  width: 100%;
  min-height: 58px;
  display: flex;
  align-items: center;
}

.serch-row-title {
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  font-weight: bold;
  color: #999999;
  margin-right: 40px;
}

.plan-tab {
  align-items: center;
  width: 77%;
  display: inline-block;
}

.plan-tab-li {
  padding: 5px 16px;
  background: #f6f7fa;
  border-radius: 4px;
  color: #333333;
  display: inline-block;
  height: 32px;
  line-height: 22px;
  cursor: pointer;
  margin-right: 16px;
}

.plan-tab-li-active {
  background: #f2f8ff;
  border-radius: 4px;
  border: 1px solid #89a4ff;
}

.plan-tab-li span {
  font-size: 12px;
  color: red;
}

.serch-save-prompt {
  width: 100%;
  padding: 10px;
  height: 78px;
  background: #f5f5f5;
  border-radius: 4px;
  font-size: 14px;
  color: #666666;
  line-height: 20px;
  margin-top: 24px;
}

.loading {
  text-align: center;
  margin-top: 20px;
}
</style>
